document.addEventListener("DOMContentLoaded", () => {
  let navbar_list = document.getElementById("navbar--list");
  let navbar_toggle = document.getElementById("hamburger");
  navbar_toggle.addEventListener("click", function() {
    if (this.classList.contains("hamburger--active")) {
      navbar_list.style.display = "none";
      this.classList.remove("hamburger--active");
    } else {
      navbar_list.style.display = "flex";
      this.classList.add("hamburger--active");
    }
  });
});
